@font-face {
  font-family: 'GBA';
  src: local('GBA'), url(./fonts/gba-fe-dialogue.ttf) format('truetype');
}

a {
  color: white
}

.App {
  text-align: center;
  font-family: GBA, Arial, serif;
  letter-spacing: 2px;
  color:white;
}

.App-logo {
  width: 600px;
  height: auto;
  pointer-events: none;
  user-select: none;
  -moz-user-select: none;
  object-fit:cover;
  object-position:50% 50%;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 22px
}

.App-link {
  color: #61dafb;
}

.Image-container {
  position: relative;
  /*border: 5px solid white;*/
  /*border-radius: 25px;*/
}

.Text {
  position: absolute;
  top: 22px;
  left: 36px;
  margin: 36px;
  font-family: GBA, Arial, serif;
  text-align: left;
  line-height: 12px;
  user-select: none;
  -moz-user-select: none;
  letter-spacing: 2px;
  color: black;
}

.Code-block {
  line-height: 10px;
  user-select: text !important;
  -moz-user-select: text !important;
}

.indent {
  margin-left: 36px;
}

.Link-Block > * {
  margin-left: 8px;
  margin-right: 8px;
}